import {LoginUrlParams} from './login-url-params';

/**
 * Given an URL, returns the LoginUrl params
 * @param currentUrl
 */
export function getLoginUrlParams(currentUrl: string): LoginUrlParams {
    const url = new URL(currentUrl);
    const rechargeRequestId = url.searchParams.get('rrid');
    const promoCodeId = url.searchParams.get('pcid');
    const redirectTo = url.searchParams.get('to');
    return {
        LANDING_NAME: url.searchParams.get('ln'),
        RECHARGE_REQUEST_ID: rechargeRequestId
            ? Number(rechargeRequestId)
            : null,
        PROMO_CODE_ID: promoCodeId,
        FROM: url.searchParams.get('from'),
        TO: redirectTo || process.env.NEXT_PUBLIC_SERVER_BASE_URL,
    };
}
