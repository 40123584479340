import React, {useCallback, useEffect, useState} from 'react';
import {
    LoginDialog,
    LoginDialogCloseReason,
} from '../../components/login/login-dialog';
import useTranslation from 'next-translate/useTranslation';
import {getRedirectUrlAfterLogin} from '../../modules/login/get-redirect-url-after-login';
import {usePersonState} from '../../modules/person/context';
import {useGlobalLoadingIndicator} from '../../modules/global-loading-indicator/context';
import {getRedirectUrlAfterLoginFailure} from '../../modules/login/get-redirect-url-after-login-failure';
import {analytics} from '../../modules/analytics/analytics';
import {PAGE_EVENTS} from '../../modules/util/constants';
import {useRollbar} from '@rollbar/react';

export default function Login(): JSX.Element {
    const {t} = useTranslation('auth');
    const [showDialog, setShowDialog] = useState(false);
    const personState = usePersonState();
    const {showLoadingIndicator, hideLoadingIndicator} =
        useGlobalLoadingIndicator();

    const {isLoggedIn} = personState;

    
    const rollbar = useRollbar();

    useEffect(() => {
        !isLoggedIn && setShowDialog(true);
    }, [isLoggedIn]);

    // Page load tracking
    useEffect(() => {
        void analytics.page(PAGE_EVENTS.LOGIN_PAGE);
        
    }, []);

    const handleClose = useCallback((reason: LoginDialogCloseReason) => {
        setShowDialog(false);
        console.log(reason);
        window.location.assign(
            getRedirectUrlAfterLoginFailure(window.location.href)
        );
    }, []);

    useEffect(() => {
        if (personState.isLoggedIn) {
            showLoadingIndicator();
            void getRedirectUrlAfterLogin(
                window.location.href,
                personState.signedUp,
                personState,
                t,
                
                rollbar
            ).then((urlToRedirect) => {
                window.location.assign(urlToRedirect);
            });
        }
        
    }, [personState.isLoggedIn]);

    useEffect(() => {
        if (!showDialog) {
            showLoadingIndicator();
        }
        if (showDialog) {
            hideLoadingIndicator();
        }
        
    }, [showDialog]);

    return (
        <>
            <LoginDialog
                show={showDialog}
                onClose={handleClose}
                closeDialogOnFailure={false}
                closeDialogOnSuccess={false}
                disableBackdropClick={true}
            />
        </>
    );
}
