import {getLoginUrlParams} from './get-login-url-params';

/**
 * This function returns the URL where we should redirect the user
 * after the login process fails.
 *
 * @param currentUrl
 */
export function getRedirectUrlAfterLoginFailure(currentUrl: string): string {
    const urlParams = getLoginUrlParams(currentUrl);
    if (urlParams.FROM) {
        return urlParams.FROM;
    }
    return '/';
}
