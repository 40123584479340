import {PersonState} from '../person/state';
import {TOAST_TYPES} from '../util/constants';
import {appendMessageToUrl} from '../util/utilities/append-message-to-url';
import {getLoginUrlParams} from './get-login-url-params';
import {processPromoCode} from '../promo-code-processor/process-promo-code';
import {processRechargeRequest} from '../recharge-request-processor/process-recharge-request';
import Rollbar from 'rollbar';

/**
 * This function returns the URL where we should redirect the user
 * after the login process is completed.
 *
 * It handles processing the gift generator or the promo code.
 *
 * @param currentUrl The current URL.
 * @param isSignUp Indicates if the user ust signed in.
 * @param personState
 * @param t Translation function
 */
export async function getRedirectUrlAfterLogin(
    currentUrl: string,
    isSignUp: boolean,
    personState: PersonState,
    t: (
        key: string,
        ...query: {[name: string]: string | number | boolean}[]
    ) => string,
    rollbar: Rollbar
): Promise<string> {
    const urlParams = getLoginUrlParams(currentUrl);
    if (urlParams.PROMO_CODE_ID) {
        const result = await processPromoCode(
            urlParams.PROMO_CODE_ID,
            personState,
            urlParams.LANDING_NAME
        );
        if (result.redirectTo) {
            return result.redirectTo;
        } else {
            return appendMessageToUrl(
                urlParams.LANDING_NAME ? '/' : '/account/promos_discounts',
                t('auth.unknown-promo-code-error'),
                TOAST_TYPES.ERROR
            );
        }
    } else if (urlParams.RECHARGE_REQUEST_ID) {
        const result = await processRechargeRequest(
            urlParams.RECHARGE_REQUEST_ID,
            personState,
            rollbar
        );
        return result.redirectTo;
    } else {
        const message = isSignUp
            ? t('auth.welcome-message', {
                  firstName: personState.person.firstName,
                  project_display_name: process.env.NEXT_PUBLIC_PROJECT_NAME_DISPLAY
              })
            : null;
        return appendMessageToUrl(urlParams.TO, message, undefined, false);
    }
}
